import * as React from "react"
import { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"

const BlogPagination = ({ pageInfo }) => {
  const [active, setActive] = useState(pageInfo.currentPage)
  const [items, setItems] = useState([])

  const handlePagination = number => {
    if (active === number) return
    navigate(`/blog/${number === 1 ? "" : number}`)
  }

  useEffect(() => {
    const newItems = []
    let least = pageInfo.currentPage - 2
    if (least < 1) least = 1

    let most = pageInfo.currentPage + 2
    if (most > pageInfo.pageCount) most = pageInfo.pageCount

    for (let number = least; number <= most; number++) {
      newItems.push(
        <Link
          key={number}
          to={`/blog/${number === 1 ? "" : number}`}
          className={number === active && "current-page"}
        >
          {number}
        </Link>
      )
    }
    setItems(newItems)
  }, [])

  return (
    <div className="pagination bot-element">
      <div className="pr-bg pr-bg-white" />
      <div className="container">
        <a
          onClick={() =>
            pageInfo.currentPage !== 1 &&
            handlePagination(pageInfo.currentPage - 1)
          }
          className="prevposts-link"
        >
          <i className="fal fa-long-arrow-left" />
        </a>
        {items}
        <a
          onClick={() =>
            pageInfo.hasNextPage && handlePagination(pageInfo.currentPage + 1)
          }
          className="nextposts-link"
        >
          <i className="fal fa-long-arrow-right" />
        </a>
      </div>
    </div>
  )
}

export default BlogPagination
