import * as React from "react"
import { useState, useEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link, navigate } from "gatsby"
import ContentWrapper from "../components/Shared/ContentWrapper"
import BlogPostMediaPreview from "../components/Blog/BlogPostMediaPreview"
import BlogPagination from "../components/Blog/BlogPagination"
import BlogPostItem from "../components/Blog/BlogPostItem"
import BlogNav from "../components/Blog/BlogNav"

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      pageInfo {
        currentPage
        itemCount
        pageCount
        perPage
        hasNextPage
      }
      nodes {
        id
        title
        slug
        postType
        createdAt(fromNow: true)
        categories {
          id
          name
          slug
        }
        tags {
          id
          name
          slug
        }
        excerpt {
          excerpt
        }
        featuredImage {
          file {
            url
          }
        }
      }
    }
  }
`

const Blog = ({ data }) => {
  const { nodes: allPosts, pageInfo } = data.allContentfulBlogPost

  return (
    <Layout title="Blog">
      <SEO title="Blog" />

      <ContentWrapper nav={<BlogNav />}>
        {/*<!-- fixed-top-panel-->*/}
        {/*<BlogNav />*/}
        {/*<!-- fixed-top-panel end -->*/}

        {/*<!--section -->*/}
        {Array.isArray(allPosts) &&
          allPosts.map(post => <BlogPostItem key={post.id} post={post} />)}
        {/*<!--section end -->*/}
        <div className="section-separator bot-element">
          <span className="fl-wrap" />
        </div>
        {/*<!--section -->*/}

        {/*<!--pagination-->*/}
        <BlogPagination pageInfo={pageInfo} />
        {/*<!--pagination end-->*/}
        <div className="limit-box fl-wrap" />
      </ContentWrapper>
    </Layout>
  )
}

export default Blog
